import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";

const Map = () => {
  const [projects, setProjects] = useState([]);
  const [currentLayer, setCurrentLayer] = useState("streets"); // Track the current layer
  const mapRef = useRef(); // Reference to the map

  // Function to detect the current language from the <html> tag
  const detectLanguage = () => {
    const lang = document.documentElement.lang || "en"; // Default to 'en' if no lang attribute is found
    console.log("Detected Language:", lang); // Log the detected language
    return lang;
  };

  // Fetch project data from the provided API endpoint based on language
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const lang = detectLanguage(); // Get the current language ('en' or 'ar')
        const apiUrl = `https://yamen-projects-api.vercel.app/api/projects`; // API endpoint for projects
        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log("Fetched Project Data:", data); // Log the fetched project data
        // We assume the data has the structure with projects
        if (data && data.length > 0) {
          setProjects(data[0].projects); // Assuming the first object has a `projects` array
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjects();
  }, []);

  // Function to create a custom marker icon with project number
  const createCustomMarker = (project, index) => {
    return L.divIcon({
      className: "custom-marker", // Class for custom styles
      html: `<div class="marker-content">${index + 1}</div>`, // Number of the project
      iconSize: [30, 30], // Size of the marker
    });
  };

  // Layer URLs for Google Streets and Satellite
  const layers = {
    streets: "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
    satellite: "http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}",
  };

  // Component to handle layer changes
  const LayerControl = () => {
    const map = useMap(); // Hook to get the map instance

    const handleLayerChange = (layer) => {
      setCurrentLayer(layer); // Update the current layer
      const newLayer = L.tileLayer(layers[layer], {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      });

      map.eachLayer((existingLayer) => {
        if (existingLayer instanceof L.TileLayer) {
          map.removeLayer(existingLayer); // Remove existing tile layer
        }
      });

      map.addLayer(newLayer); // Add new tile layer
    };

    return (
      <div className="layer-toggle">
        <button
          className={currentLayer === "streets" ? "active" : ""}
          onClick={() => handleLayerChange("streets")}
        >
          Street View
        </button>
        <button
          className={currentLayer === "satellite" ? "active" : ""}
          onClick={() => handleLayerChange("satellite")}
        >
          Satellite View
        </button>
      </div>
    );
  };

  return (
    <div>
      {/* Map container */}
      <MapContainer
        center={[23.8859, 45.0792]} // Centered on Saudi Arabia
        zoom={6}
        style={{ height: "100vh", width: "100%" }}
        ref={mapRef} // Reference to the map instance
      >
        <TileLayer
          url={layers[currentLayer]} // Set the layer based on state
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={20}
        />

        {/* Render markers based on fetched project data */}
        {projects.map((project, index) => (
          <Marker
            key={project.id}
            position={[project.latitude, project.longitude]}
            icon={createCustomMarker(project, index)} // Custom marker
            eventHandlers={{
              click: () => {
                mapRef.current.setView(
                  [project.latitude, project.longitude],
                  13,
                  { animate: true }
                );
              },
            }}
          >
            <Popup>
              {/* Log the title and label data */}
              {console.log("Project Title Data:", project.title)}
              {console.log("Project Label Data:", project.label)}

              <h2>
                {
                  // Safely access the title based on the detected language
                  project.title && project.title[detectLanguage()]
                    ? project.title[detectLanguage()]
                    : project.title?.en || "Untitled"
                }
              </h2>
              <p>
                {
                  // Safely access the label based on the detected language
                  project.label && project.label[detectLanguage()]
                    ? project.label[detectLanguage()]
                    : project.label?.en || "No description available"
                }
              </p>
            </Popup>
          </Marker>
        ))}

        {/* Custom Layer Control */}
        <LayerControl />
      </MapContainer>

      {/* Back button */}
      <Link className="back-btn" to="/">
        <i className="fa-solid fa-arrow-right"></i>
      </Link>
    </div>
  );
};

export default Map;

import React, { useEffect } from 'react';
import './all.min.css';
import './normalize.css';
import './style.css';
import './media.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './i18n'; // import the i18n configuration
import { useTranslation } from 'react-i18next';
import Map from './components/Map.jsx';
import Main from './pages/Main';

import Project from './components/Project.jsx';
const App = () => {
  const { i18n } = useTranslation(); // Get i18n instance to detect language changes

  useEffect(() => {
    // Detect language and update the document's dir attribute dynamically
    const currentLang = i18n.language;
    const direction = currentLang === 'ar' ? 'rtl' : 'ltr';

    // Apply the direction to the <html> tag
    document.documentElement.setAttribute('dir', direction);
    document.documentElement.lang = currentLang; // Optionally set lang attribute

    // Manually set the document title based on the current language
    const title = currentLang === 'ar' ? 'يامن' : 'Yamin';
    document.title = title; // Set the document title manually
  }, [i18n.language]); // Run this useEffect whenever the language changes

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />        <Route path="/map" element={<Map />} />
        <Route path="/project/:id" element={<Project />} />
      </Routes>
    </Router>
  );
};

export default App;

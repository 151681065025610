import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import bgVideo from "../assets/video.mp4";
import fallbackGif from "../assets/video.gif";

const Home = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    // Attempt to play the video programmatically
    const playVideo = async () => {
      if (videoElement) {
        try {
          await videoElement.play();
          setShowFallback(false); // If video plays, ensure fallback is hidden
        } catch (error) {
          console.log("Autoplay failed, showing fallback image.", error);
          setShowFallback(true); // Show fallback if autoplay fails
        }
      }
    };

    playVideo();

    // Fallback: detect when the page regains focus, try playing again in case of power mode changes
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        playVideo();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <section id="main" className="main-section">
      <h3 dangerouslySetInnerHTML={{ __html: t("companySlogan2") }}></h3>

      <a href="#about" className="scroll-text">
        {t("scrollDown")}
      </a>

      {/* Conditionally show video or fallback */}
      {!showFallback ? (
        <video
          ref={videoRef}
          id="bg-video"
          className="bg-video"
          src={bgVideo}
          autoPlay="autoplay"
          playsInline="playsinline"
          loop="true"
          muted="true"
          controls={false} // Hide video controls
          preload="auto" // Preload the video
          controlsList="nodownload nofullscreen noremoteplayback" // Prevent controls from showing
          title="Background Video"
        />
      ) : (
        <img
          src={fallbackGif}
          alt="Background Animation"
          className="bg-image"
        />
      )}
    </section>
  );
};

export default Home;

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

// Import SVGs as React components
import { ReactComponent as Partner1 } from "../assets/partners/1.svg";
import { ReactComponent as Partner2 } from "../assets/partners/2.svg";
import { ReactComponent as Partner3 } from "../assets/partners/3.svg";
import { ReactComponent as Partner4 } from "../assets/partners/4.svg";
import { ReactComponent as Partner5 } from "../assets/partners/5.svg";
import { ReactComponent as Partner6 } from "../assets/partners/6.svg";
import { ReactComponent as Partner7 } from "../assets/partners/7.svg";
import { ReactComponent as Partner8 } from "../assets/partners/8.svg";
import { ReactComponent as Partner9 } from "../assets/partners/9.svg";
import { ReactComponent as Partner10 } from "../assets/partners/10.svg";
import { ReactComponent as Partner11 } from "../assets/partners/11.svg";
import { ReactComponent as Partner12 } from "../assets/partners/12.svg";
import { ReactComponent as Partner13 } from "../assets/partners/13.svg";
import { ReactComponent as Partner14 } from "../assets/partners/14.svg";
import { ReactComponent as Partner15 } from "../assets/partners/15.svg";
import { ReactComponent as Partner16 } from "../assets/partners/16.svg";
import { ReactComponent as Partner17 } from "../assets/partners/17.svg";

const Partners = () => {
  const { t } = useTranslation();
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const imgContainer = imgContainerRef.current;
    const images = imgContainer.querySelectorAll(".image");

    const imageWidth = 240; // Width of the image only (no margin)
    const margin = 40; // Adjust this value for desired spacing between images
    const totalWidth = imageWidth + margin;

    // Set initial scroll speed based on screen size
    let scrollSpeed = window.innerWidth <= 768 ? 2 : 1.25;

    // Update scroll speed on window resize
    const handleResize = () => {
      scrollSpeed = window.innerWidth <= 768 ? 2 : 1.25;
    };
    window.addEventListener("resize", handleResize);

    images.forEach((img, index) => {
      img.style.left = `${index * totalWidth}px`;
    });

    const scrollImages = () => {
      images.forEach((img) => {
        let currentX = parseInt(img.style.left, 10);
        let newX = currentX - scrollSpeed;

        // Check if the image has fully scrolled off-screen and reset its position
        if (newX + totalWidth < 0) {
          let maxLeft = Math.max(
            ...Array.from(images).map((i) => parseInt(i.style.left, 10))
          );
          img.style.left = `${Math.round(maxLeft + totalWidth)}px`;
        } else {
          img.style.left = `${Math.round(newX)}px`; // Use Math.round here
        }
      });

      requestAnimationFrame(scrollImages);
    };

    scrollImages(); // Start the animation

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section id="partners">
      <div className="container">
        <h3>{t("partnersInSuccess")}</h3>
        <div className="img-container" ref={imgContainerRef}>
          <div className="image"><Partner1 /></div>
          <div className="image"><Partner10 /></div>
          <div className="image"><Partner2 /></div>
          <div className="image"><Partner13 /></div>
          <div className="image"><Partner3 /></div>
          <div className="image"><Partner12 /></div>
          <div className="image"><Partner4 /></div>
          <div className="image"><Partner14 /></div>
          <div className="image"><Partner5 /></div>
          <div className="image"><Partner11 /></div>
          <div className="image"><Partner6 /></div>
          <div className="image"><Partner15 /></div>
          <div className="image"><Partner7 /></div>
          <div className="image"><Partner16 /></div>
          <div className="image"><Partner8 /></div>
          <div className="image"><Partner9 /></div>
          <div className="image"><Partner17 /></div>
        </div>
        <div className="cutter"></div>
      </div>
    </section>
  );
};

export default Partners;

import React from "react";
import Header from "../components/Header";
import Home from "../components/Home";
import About from "../components/About";
import Brief from "../components/Brief";
import Patners from "../components/Partners";
import Projects from "../components/Projects";
import Info from "../components/Info";
import Footer from "../components/Footer";
import Map3D from "../components/Map3D";
const Main = () => {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Brief />
      <Info />
      <Projects />
      <Map3D/>
      <Patners />
      <Footer />
    </>
  );
};

export default Main;

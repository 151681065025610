import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import about from "../assets/about.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

// Custom arrow components
const NextArrow = ({ onClick, isRtl }) => (
  <div
    className={`custom-arrow next-arrow ${isRtl ? "rtl" : ""}`}
    onClick={onClick}
  >
    &#8250; {/* Unicode character for right arrow */}
  </div>
);

const PrevArrow = ({ onClick, isRtl }) => (
  <div
    className={`custom-arrow prev-arrow ${isRtl ? "rtl" : ""}`}
    onClick={onClick}
  >
    &#8249; {/* Unicode character for left arrow */}
  </div>
);

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const Projects = () => {
  const { t, i18n } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://yamen-projects-api.vercel.app/api/projects"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // Assuming the projects are inside the 'projects' array in the first object
      if (data && data.length > 0) {
        setProjects(data[0].projects); // Extract projects from the first object
      } else {
        setProjects([]); // Handle no projects
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error loading projects: {error}</div>;

  // Check if the current language is RTL
  const isRtl = i18n.language === "ar";

  const settings = {
    dots: true,
    infinite: projects.length > 1 || projects.length === 1, // Infinite scrolling for single or multiple projects
    speed: 500,
    slidesToShow: 3, // Always show 3 slides (adjust this for layout)
    slidesToScroll: 1,
    autoplay: projects.length > 1,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <NextArrow isRtl={isRtl} />,
    prevArrow: <PrevArrow isRtl={isRtl} />,
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 2.5, // Show 2 slides on medium screens
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2, // Show 2 slides on medium screens
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.5, // Show 2 slides on medium screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: projects.length > 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  // Repeat the single project when there's only one project
  const projectsToRender =
    projects.length === 1 ? [...Array(3)].map(() => projects[0]) : projects;

  return (
    <section id="projects">
      <div className="container">
        {projectsToRender.length > 0 ? (
          <Slider {...settings} className="projects-slider">
            {projectsToRender.map((project, index) => (
              <div key={index} className="project-box">
                <div className="image">
                  <img
                    src={project.thumbnail || about}
                    alt={project.title[i18n.language]}
                  />
                </div>
                <div className="options">
                  <Link to={`/project/${project.id}`}><span>{t("showMore")}</span></Link>
                  <div className="text">
                    <h4>{project.title[i18n.language]}</h4>
                    <h5>{project.location[i18n.language]}</h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div>No projects available.</div>
        )}
      </div>
    </section>
  );
};

export default Projects;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import Footer from "./Footer";
import Form from "./Form"; // Import the Form component
import about from "../assets/about.jpg";

// Custom arrow components
const NextArrow = ({ onClick, isRtl }) => (
  <div
    className={`custom-arrow next-arrow ${isRtl ? "rtl" : ""}`}
    onClick={onClick}
  >
    &#8250; {/* Unicode character for right arrow */}
  </div>
);

const PrevArrow = ({ onClick, isRtl }) => (
  <div
    className={`custom-arrow prev-arrow ${isRtl ? "rtl" : ""}`}
    onClick={onClick}
  >
    &#8249; {/* Unicode character for left arrow */}
  </div>
);

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const Project = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    const fetchProject = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://yamen-projects-api.vercel.app/api/projects" // Replace with your API endpoint
        );
        const data = await response.json();

        // Assuming the structure is an array with one object containing the 'projects' array
        const selectedProject = data[0].projects.find((proj) => proj.id === id);

        if (selectedProject) {
          setProject(selectedProject);
        } else {
          setError("Project not found");
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
        setError("Failed to load project details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Check if the current language is RTL
  const isRtl = i18n.language === "ar";

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    rtl: isRtl, // Enables RTL support for react-slick
    nextArrow: <NextArrow isRtl={isRtl} />,
    prevArrow: <PrevArrow isRtl={isRtl} />,
  };

  const customLinks = {
    home: "../",
    about: "../#about",
    projects: "../#projects",
    partners: "../#partners",
  };

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setModalOpen(false); // Close the modal after submission
  };

  return (
    <>
      <Header customLinks={customLinks} />
      <div className="project-images">
        <div className="project-info">
          <h3>{project.title[i18n.language]}</h3>
          <h4>{project.location[i18n.language]}</h4>
        </div>
        <Slider {...settings}>
          <div>
            <img
              src={project.thumbnail || about}
              alt={project.title[i18n.language]}
              style={{ width: "100%" }}
            />
          </div>
          {project.images &&
            project.images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Project ${index + 1}`}
                  style={{ width: "100%" }}
                />
              </div>
            ))}
        </Slider>
      </div>

      <div className="project-details">
        <div className="container">
          <h3 className="title">{project.slogan[i18n.language]}</h3>
          <p className="project-brief">{project.description[i18n.language]}</p>
          <div className="info-container">
            <div className="info">
              <span>{project.building_area}</span>
              <h3>{t("buildingArea")}</h3>
            </div>
            <div className="info">
              <span>{project.land_area}</span>
              <h3>{t("landArea")}</h3>
            </div>
            <div className="info">
              <span>{project.units}</span>
              <h3>{t("unitCount")}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-btn" onClick={() => setModalOpen(true)}>
        {t("register")} {/* "سجل عندنا" or "Register" */}
      </div>

      {/* Use the Form component */}
      <Form
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        formData={formData}
        handleChange={handleFormDataChange}
        handleSubmit={handleFormSubmit}
      />

      <Footer customLinks={customLinks} />
    </>
  );
};

export default Project;

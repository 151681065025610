import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

const Form = ({ modalOpen, setModalOpen }) => {
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const formRef = useRef(); // Create a ref for the form

  // Simple validation function
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = t('nameRequired');
    }
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('validEmailRequired');
    }
    if (!formData.phone.trim() || !/^\+?[0-9]{10,15}$/.test(formData.phone)) {
      newErrors.phone = t('validPhoneRequired');
    }
    if (!formData.message.trim()) {
      newErrors.message = t('messageRequired');
    }

    return newErrors;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      // Send email using EmailJS
      emailjs
        .sendForm(
          'service_co8otos',
          'template_c0n3xj9',
          formRef.current,
          '4AHbIsZGQa4uSbEHW'
        )
        .then(
          () => {
            console.log('SUCCESS!');
            setModalOpen(false); // Close the modal on success
          },
          (error) => {
            console.log('FAILED...', error.text);
            alert('فشل الارسال يرجة المحاولة لاحقا'); // Alert user on failure
          }
        );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    modalOpen && (
      <div
        className={`form-modal ${modalOpen ? 'form-modal-open' : ''}`}
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      >
        <div className="form-modal-content">
          <span className="form-modal-close" onClick={() => setModalOpen(false)}>
            &times;
          </span>
          <h2>{t('register')}</h2>

          <form ref={formRef} onSubmit={handleFormSubmit}>
            <label>
              {t('name')}
              <input
                type="text"
                name="name" // Fixed: Changed from "from_name" to "name" for formData
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </label>

            <label>
              {t('email')}
              <input
                type="email"
                name="email" // Fixed: Changed from "from_email" to "email" for formData
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </label>

            <label>
              {t('phone')}
              <input
                type="tel"
                name="phone" // Changed to "phone" to match formData
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </label>

            <label>
              {t('messageForm')}
              <textarea
                name="message" // Changed to "message" to match formData
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {errors.message && <span className="error">{errors.message}</span>}
            </label>

            <button type="submit">{t('submit')}</button>
          </form>
        </div>
      </div>
    )
  );
};

export default Form;

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import about from "../assets/about.jpg";

const About = () => {
  const { t } = useTranslation();
  const infoSection = useRef(null); // To reference the section
  const totalProjectsRef = useRef(null); // To reference the first span
  const crewRef = useRef(null); // To reference the second span
  const apartmentsRef = useRef(null); // To reference the third span

  // Function to animate the count and add "+" when completed
  const animateValue = (ref, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const currentValue = Math.floor(progress * (end - start) + start);
      ref.current.innerText = progress < 1 ? currentValue : `${currentValue}+`; // Add "+" at the end
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // When the section is in view, trigger the counting animations
          animateValue(totalProjectsRef, 0, 16, 2000); // Count to 1500 in 2 seconds
          animateValue(crewRef, 0, 25, 2000); // Count to 500 in 2 seconds
          animateValue(apartmentsRef, 0, 66, 2000); // Count to 1000 in 2 seconds
          observer.disconnect(); // Once animated, stop observing
        }
      },
      { threshold: 0.5 } // Start counting when 50% of the section is visible
    );

    if (infoSection.current) {
      observer.observe(infoSection.current);
    }

    return () => {
      if (infoSection.current) {
        observer.unobserve(infoSection.current);
      }
    };
  }, []);

  return (
    <section id="about" ref={infoSection}>
      <div className="container">
        <div className="text">
          <div className="heading">
            <span></span>
            <h3>{t("about")}</h3>
          </div>
          <h4>{t("companySlogan1")}</h4>
          <p>{t("companyDescription1")}</p>
          <p> {t("companyBrief")}</p>

          <div className="info-container">
            <div className="info">
              <h3>{t("totalProjects")}</h3>
              <span ref={totalProjectsRef}>0</span>
            </div>
            <div className="info">
              <h3>{t("crew")}</h3>
              <span ref={crewRef}>0</span>
            </div>
            <div className="info">
              <h3>{t("apartments")}</h3>
              <span ref={apartmentsRef}>0</span>
            </div>
          </div>
        </div>
        <div className="image">
          <img src={about} alt="" />
        </div>
      </div>
    </section>
  );
};

export default About;

import React from "react";
import { useTranslation } from "react-i18next";
import logo2 from "../assets/logo3.png";
const Footer = ({customLinks}) => {
  const { t, i18n } = useTranslation();
  const links = customLinks || {
    partners: "#partners",
    about: "#about",
    projects: "#projects",
  };
  return (
    <footer>
      <div className={`footer-content footer-${i18n.language}`}>
        <div className="footer-container container">
          {/* About Section */}
          <ul className="about-list">
            <img src={logo2} alt={t("logoAlt")} className="logo" />
            <p dangerouslySetInnerHTML={{ __html: t('companyDescription') }}></p>
          </ul>

          {/* Menu Section */}
          <ul className="menu">
            <h3>{t("mainMenu")}</h3>
            <li>
              <a href={links.about}>{t("about")}</a>
            </li>
            <li>
              <a href={links.projects}>{t("projects")}</a>
            </li>
            <li>
              <a href={links.partners}>{t("partners")}</a>
            </li>
          </ul>

          {/* Contact Section */}
          <ul className="contact-list">
            <h3>{t("contactUs")}</h3>
            <li>
              <span>0138355558</span>
            </li>
            <li>
              <span>info@yamin.estate</span>
            </li>
          </ul>
        </div>

        {/* Footer Rights Section */}
        <div className="footer-rights">
          <p>{t("footerRights")}</p>
          <div className="icons">
            <a href="https://x.com/yamin_estate?s=21&t=H4irpWbMMVbnMHkXMruVmA" target="_blank" rel="noreferrer">
              <i class="fa-brands fa-x-twitter"></i>
            </a>
            <a href="https://www.instagram.com/yamin_estate?igsh=MTZpYWo2aWQxbXZzOA==" target="_blank" rel="noreferrer">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="mailto: info@yamin.estate">
              <i class="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

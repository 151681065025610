import React from "react";
import { useTranslation } from "react-i18next";
import icon1 from "../assets/icons/1.png";
import icon2 from "../assets/icons/2.png";
import icon3 from "../assets/icons/3.png";
import icon4 from "../assets/icons/4.png";
import pattern from "../assets/pattern.png";
const Info = () => {
  const { t } = useTranslation();
  return (
    <section id="info">
      <div className="container">
        <div className="text">
          <h3 className="title">{t("whyYamin")}</h3>
          <p>{t("whyBrief")}</p>
        </div>

        <div className="features-container">
          <div className="feature">
            <img src={icon4} alt={t("quality")} />
            <h4>{t("quality")}</h4>
            <p>{t("qualityBrief")}</p>
          </div>

          <div className="feature">
            <img src={icon3} alt={t("innovation")} />
            <h4>{t("innovation")}</h4>
            <p>{t("innovationBrief")}</p>
          </div>

          <div className="feature">
            <img src={icon2} alt={t("sustainability")} />
            <h4>{t("sustainability")}</h4>
            <p>{t("sustainabilityBrief")}</p>
          </div>

          <div className="feature">
            <img src={icon1} alt={t("integrity")} />
            <h4>{t("integrity")}</h4>
            <p>{t("integrityBrief")}</p>
          </div>
        </div>
      </div>
      <div className="bg-img">
        <img src={pattern} alt="" />
      </div>
    </section>
  );
};

export default Info;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Form from "./Form"; // Import the Form component

// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
);

const Header = ({ customLinks }) => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false); // State for the menu
  const [loading, setLoading] = useState(false); // State for loading overlay
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const toggleLanguage = async () => {
    setLoading(true); // Show loading overlay

    const newLang = i18n.language === "ar" ? "en" : "ar";

    // Introduce a delay of 2 seconds (2000 milliseconds) for the loading spinner
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Change language and hide the loading spinner afterward
    await i18n.changeLanguage(newLang);
    setLoading(false); // Hide loading overlay after language change
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // Toggle the menu open/close state
  };

  // Fallback to default links if no customLinks are provided
  const links = customLinks || {
    home: "#main",
    about: "#about",
    projects: "#projects",
  };

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setModalOpen(false); // Close the modal after submission
  };

  return (
    <>
      {loading && <LoadingSpinner />} {/* Show loading overlay when loading */}
      <header>
        <nav className={`header-${i18n.language}`}>
          <div className="container">
            <Link to="/" className="logo">
              <img src={logo} alt={t("logoAlt")} />
            </Link>
            <ul className="nav-list">
              <li>
                <a href={links.home}>{t("home")}</a>
              </li>
              <li>
                <a href={links.about}>{t("about")}</a>
              </li>
              <li>
                <a href={links.projects}>{t("projects")}</a>
              </li>
              <li>
                <a href="#" onClick={() => setModalOpen(true)}>
                  {t("register")}
                </a>
              </li>
            </ul>
            <div className="icons">
              <a href="mailto: info@yamin.estate">{t("contactUs")}</a>
              <Link to="/map">
                <i className="fa-solid fa-magnifying-glass"></i>
              </Link>
              <div className="language-switcher">
                <button onClick={toggleLanguage}>
                  {i18n.language === "ar" ? "EN" : "AR"}
                </button>
              </div>
              <div className="menu-item">
                <div className="menu-icon" onClick={toggleMenu}>
                  <i className="fa-solid fa-bars"></i>
                </div>
                <ul className={`dropdown-menu ${menuOpen ? "open" : ""}`}>
                  <div className="close-btn" onClick={toggleMenu}>
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                  <li>
                    <a href={links.home}>{t("home")}</a>
                  </li>
                  <li>
                    <a href={links.about}>{t("about")}</a>
                  </li>
                  <li>
                    <a href={links.projects}>{t("projects")}</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setModalOpen(true)}>
                      {t("register")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <Form
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          formData={formData}
          handleChange={handleFormDataChange}
          handleSubmit={handleFormSubmit}
        />
      </header>
    </>
  );
};

export default Header;

import React from "react";
import { useTranslation } from "react-i18next";
import pattern from "../assets/pattern.png";

const Brief = () => {
  const { t } = useTranslation();

  return (
    <section id="brief">
      <div className="bg-img">
        <img src={pattern} alt="" />
      </div>
      <div className="container">
        <div className="vision">
          <h3>{t("visionTitle")}</h3>
          <p>{t("vision")}</p>
        </div>
        <div className="message">
          <h3>{t("messageTitle")}</h3>
          <p>{t("message")}</p>
        </div>
      </div>
      <div className="cutter"></div>
    </section>
  );
};

export default Brief;

import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Rectangle,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import saudiArabiaGeoJson from "../saudiArabiaGeoJson.json"; // GeoJSON for Saudi Arabia
import logo from "../assets/logo3.png";

// Custom map component to restrict zoom
const DisableZoomControl = ({ map }) => {
  useEffect(() => {
    if (map) {
      map.on("zoomend", () => {
        const minZoom = 6; // Set the minimum zoom level
        if (map.getZoom() < minZoom) {
          map.setZoom(minZoom);
        }
      });
    }
  }, [map]);

  return null;
};

const Map3D = () => {
  const [projects, setProjects] = useState([]);
  const mapRef = useRef(null); // Create a ref for map

  // Function to detect the current language from the <html> tag
  const detectLanguage = () => {
    return document.documentElement.lang || "en"; // Default to 'en' if no lang attribute is found
  };

  // Style for Saudi Arabia (highlighted)
  const saudiStyle = {
    fillColor: "#fff", // Fully transparent fill for Saudi Arabia
    color: "#82735E", // Border color for Saudi Arabia
    weight: 3, // Border weight
    opacity: 1, // Border opacity
    fillOpacity: 0.2, // No fill color
  };

  // Define the bounds for the dimming layer
  const bounds = [
    [-90, -180], // Southwest corner (minimum latitude and longitude)
    [90, 180],   // Northeast corner (maximum latitude and longitude)
  ];

  // Create a style for the dimming layer
  const dimmingStyle = {
    fillColor: "#111",
    fillOpacity: 0.8,
    weight: 0,
  };

  // Adjust initial zoom level based on screen size
  const getInitialZoom = () => {
    if (window.innerWidth <= 768) {
      return -3; // For smaller screens (like mobile)
    }
    return 6; // Default zoom for larger screens
  };

  // Fetch project data from the provided API endpoint based on language
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const lang = detectLanguage(); // Get the current language ('en' or 'ar')
        const apiUrl = `https://yamen-projects-api.vercel.app/api/projects`; // New API endpoint
        const response = await fetch(apiUrl);
        const data = await response.json();

        // Assuming the data is structured as per your new API response
        const projectsData = data[0].projects; // Extract the 'projects' array from the response

        setProjects(projectsData); // Store the fetched projects directly
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      // Disable scroll zoom by default
      mapRef.current.scrollWheelZoom.disable();

      // Enable scroll zoom on map click
      mapRef.current.on("click", () => {
        mapRef.current.scrollWheelZoom.enable();
      });

      // Disable scroll zoom when clicking outside the map
      const disableScrollZoom = (e) => {
        if (!mapRef.current.getContainer().contains(e.target)) {
          mapRef.current.scrollWheelZoom.disable();
        }
      };

      document.addEventListener("click", disableScrollZoom);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener("click", disableScrollZoom);
      };
    }
  }, []);

  return (
    <section id="map">
      <img src={logo} alt="Logo" className="logo" />
      <div className="map-container">
        <MapContainer
          center={[23.8859, 45.0792]}
          zoom={getInitialZoom()} // Use dynamic initial zoom level based on screen size
          minZoom={6} // Set minimum zoom level directly on MapContainer
          style={{ height: "85vh", width: "100%" }}
          zoomControl={false} // Disable zoom buttons
          maxBounds={bounds} // Restrict panning to a region around Saudi Arabia
          whenCreated={(map) => (mapRef.current = map)} // Store map reference on creation
        >
          {/* Prevent zoom out and zoom in */}
          <DisableZoomControl map={mapRef.current} />

          {/* Base map with Satellite tiles */}
          <TileLayer
            url="http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}" // Satellite view URL
            maxZoom={20}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />

          {/* Dimmed layer covering the entire map */}
          <Rectangle bounds={bounds} pathOptions={dimmingStyle} />

          {/* Apply Saudi Arabia GeoJSON with the highlighted style */}
          <GeoJSON
            data={saudiArabiaGeoJson}
            style={saudiStyle}
            onEachFeature={(feature, layer) => {
              const lang = detectLanguage(); // Detect the current language
              let name =
                lang === "ar"
                  ? feature.properties.NL_NAME_1
                  : feature.properties.NAME_1; // Choose Arabic or English based on language

              // Customize specific names
              if (lang === "ar") {
                if (name === "الحدودالشمالية") {
                  name = "الحدود الشمالية"; // Correct the name for "الحدودالشمالية"
                } else if (name === "منطقةالجوف") {
                  name = "منطقة الجوف"; // Correct the name for "منطقةالجوف"
                }
              }

              // Bind the popup with a custom class
              layer.bindPopup(`<div class="region-popUp">${name}</div>`);

              // Adding hover interaction (highlight the region and show popup)
              layer.on("mouseover", function () {
                // Highlight the region
                layer.setStyle({
                  fillColor: "#D8CFBF", // Bright color on hover
                  fillOpacity: 0.6, // Adjust opacity to highlight

                  weight: 3, // Border weight increase
                });

                // Open the popup on hover
                layer.openPopup();
              });

              layer.on("mouseout", function () {
                // Reset the style back to the original
                layer.setStyle(saudiStyle); // Reset to default style

                // Close the popup on mouseout
                layer.closePopup();
              });

              // Add click event to zoom into the region smoothly
              layer.on("click", function () {
                if (mapRef.current) {
                  mapRef.current.fitBounds(layer.getBounds(), {
                    padding: [50, 50], // Add padding around the zoomed area
                    animate: true, // Smooth zoom
                  });
                }
              });
            }}
          />

          {/* Render markers for each project */}
          <MapMarkerLayer
            projects={projects}
            detectLanguage={detectLanguage}
            mapRef={mapRef}
          />
        </MapContainer>
      </div>
    </section>
  );
};

// Component for markers to access map instance easily
const MapMarkerLayer = ({ projects, detectLanguage, mapRef }) => {
  return (
    <>
      {projects.map((project, index) => {
        const title = project.title ? project.title[detectLanguage()] : "No title";
        const description = project.label ? project.label[detectLanguage()] : "No description";

        return (
          <Marker
            key={project.id}
            position={[project.latitude, project.longitude]}
            icon={createCustomMarker(project, index)} // Custom marker
            eventHandlers={{
              click: () => {
                if (mapRef.current) {
                  mapRef.current.setView([project.latitude, project.longitude], 13, {
                    animate: true,
                  });
                }
              },
            }}
          >
            <Popup>
              <h2>{title}</h2>
              <p>{description}</p>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
};


// Helper function for creating custom markers
const createCustomMarker = (project, index) => {
  return L.divIcon({
    className: "custom-marker", // Class for custom styles
    html: `<div class="marker-content">${index + 1}</div>`, // Number of the project
    iconSize: [30, 30], // Size of the marker
  });
};

export default Map3D;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations
const resources = {
  en: {
    translation: {
      home: "Home",
      about: "About Yamin",
      projects: "Projects",
      partners: "Partners",
      contactUs: "Contact Us",
      scrollDown: "Scroll down",
      visionTitle: "Our Vision",
      messageTitle: "Our Mission",
      vision: "Over the next six years, we aim to be a leading real estate investment and development company, targeting the construction of 1,000 residential apartments and 500 studios by 2030, God willing. We will achieve this by providing comprehensive and sustainable real estate solutions, including distinguished architectural design, meticulous urban planning and high-quality construction.",
      message: "Our goal is to achieve innovation and excellence in every project we undertake by creating advanced living and working environments that meet the needs and aspirations of our clients. We aim to contribute to the development of the communities we serve, committing to quality, integrity, and social and environmental responsibility in all aspects of our work.",
      footerText: "All rights reserved",
      totalProjects: "Success partners",
      crew: "Crew",
      apartments: "Apartments",
      companyDescription: "One of the leading companies in the field<br />of real estate investment and development.",
      companyDescription1: "One of the leading companies in the field of real estate investment and development.",
      companySlogan1:"Yaman for Investment and Real Estate Development embodies innovation, sophistication, and excellence.",
      companySlogan2: "Projects characterized <br />by quality,elite and mastery.",
      companyBrief: "The company was established with the goal of achieving sustainable success in real estate investment and development. With a focus on innovation, quality, and meeting customer needs, Yaman aims to lead the industry within the next six years. The company targets building 1,000 residential units by 2030, God willing, through providing comprehensive and sustainable real estate solutions. These solutions are characterized by outstanding architectural design, meticulous urban planning, and high-quality construction.",
      mainMenu: "Main Menu",
      footerRights: "© 2024 All rights reserved.",
      logoAlt: "Logo",
      introText: "We specialize in real estate investment and development.",
      aboutTitle: "About Yamin",
      aboutDescription: "Yamin is a leading real estate development company with several prestigious projects.",
      whyYamin: "Why Yamin",
      whyBrief: "We strive towards the future by creating an integrated urban environment and cultural landmarks that stand out under our Kingdom’s ambitious vision to achieve sustainability and innovation for future generations.",
      quality: "Quality",
      qualityBrief: "We are committed to providing the highest standards of quality in all our projects, from design to implementation.",
      innovation: "Innovation",
      innovationBrief: "We always strive to provide innovative solutions that meet the needs of our customers and keep pace with modern developments in the real estate sector.",
      sustainability: "Sustainability",
      sustainabilityBrief: "We believe in the importance of contributing to the development of the communities in which we work through sustainable projects.",
      integrity: "Credibility",
      integrityBrief: "We work transparently and honestly in all our dealings with customers and partners",
      projectsTitle: "Our Projects",
      projectsDescription: "We have successfully completed numerous real estate development projects.",
      showMore: "show more",
      contactPhone: "Phone: +966 55 555 55 55",
      contactEmail: "Email: info@yamin.sa",
      partnersInSuccess: "Partners in Success",
      buildingArea: "Building Area (m²)",
      landArea: "Land Area (m²)",
      unitCount: "Number of Units", register: "Register", // "سجل عندنا"
      name: "Name",         // "الاسم"
      email: "Email",       // "البريد الإلكتروني"
      phone: "Phone",       // "الهاتف"
      messageForm: "Message",   // "الرسالة"
      submit: "Submit",     // "إرسال"
      nameRequired: "Name is required.",
      validEmailRequired: "A valid email is required.",
      validPhoneRequired: "A valid phone number is required.",
      messageRequired: "Message is required.",
    },
  },


  ar: {
    translation: {
      home: "الرئيسية",
      about: "عن يامن",
      projects: "مشاريع",
      partners: "شركاء النجاح",
      contactUs: "تواصل معنا",
      footerText: "جميع الحقوق محفوظة",
      scrollDown: "إسحب إلى الاسفل",
      visionTitle: "رؤيتنا",
      messageTitle: "رسالتنا",
      message: "نهدف إلى تحقيق الابتكار والتميز في كل مشروع نقوم به من خلال توفير بيئات معيشة وعمل متطورة تلبي احتياجات وتطلعات عملائنا، وتسهم في تطوير المجتمعات التي نعمل فيها. نحن ملتزمون بالجودة والنزاهة والمسؤولية الاجتماعية والبيئية في جميع جوانب أعمالنا.",
      vision: "خلال السنوات الست المقبلة، نسعى لأن نكون شركة رائدة في مجال الاستثمار والتطوير العقاري، مستهدفين بناء 1000 وحدة سكنية بحلول 2030 بإذن الله. سنحقق ذلك من خلال تقديم حلول عقارية شاملة ومستدامة، تشمل تصميما معماريًا متميزًا وتخطيطا عمرانيا دقيقا وبناءً ذا جودة عالية.",
      totalProjects: "شريك نجاح",
      crew: "فريق عمل",
      apartments: "شقق سكنية",
      companyDescription: "من الشركات الرائدة في مجال<br />الاستثمار والتطوير العقاري.",
      companyDescription1: "من الشركات الرائدة في مجال الاستثمار والتطوير العقاري.",
      companySlogan1: "تجسد يامن للإستثمار والتطوير العقاري الابتكار والرقي والتميز.",
      companySlogan2: "مشاريع تتميز بالجودة<br />والنخبة والاتقان.",
      companyBrief: "تأسست الشركة بهدف تحقيق نجاح مستدام في قطاع الاستثمار والتطوير العقاري، مع التركيز على الابتكار والجودة وتلبية احتياجات العملاء خلال السنوات الست المقبلة، تسعى الشركة لأن تكون رائدة في مجال الاستثمار والتطوير العقاري، حيث تهدف إلى بناء عدد 1000 وحدة سكنية بحلول 2030 بمشيئة الله من خلال تقديم حلول عقارية سكنية شاملة ومستدامة تتضمن تصميما معماريًا رائعا وتخطيطا عمرانيا متقناً وبناءً عالي الجودة.",
      mainMenu: "القائمة الرئيسية",
      footerRights: "حقوق النشر © 2024 - جميع الحقوق محفوظة.",
      logoAlt: "الشعار",
      whyYamin: "لماذا يامن",
      whyBrief: "نسعى نحو المستقبل بإنشاء بيئة عمرانية متكاملة، ومعالم حضارية تبرز تحت رؤية مملكتنا الطموحة لتحقيق الاستدامة والابتكار للأجيال المستقبلية.",
      quality: "الجودة",
      qualityBrief: "نلتزم بتقديم أعلى معايير الجودة في جميع مشاريعنا، من التصميم إلى التنفيذ.",
      innovation: "الابتكار",
      innovationBrief: "نسعى دائمًا لتقديم حلول مبتكرة تلبي احتياجات عملائنا وتواكب التطورات الحديثة في قطاع العقارات.",
      sustainability: "الاستدامة",
      sustainabilityBrief: "نؤمن بأهمية المساهمة في تطوير المجتمعات التي نعمل فيها من خلال مشاريع مستدامة.",
      integrity: "المصداقية",
      integrityBrief: "نعمل بشفافية وأمانة في جميع تعاملاتنا مع العملاء والشركاء",
      showMore: "عرض المزيد",
      contactPhone: "الهاتف: +966 55 555 55 55",
      contactEmail: "البريد الإلكتروني: info@yamin.sa",
      partnersInSuccess: "شركاء النجاح",
      buildingArea: "مساحة البناء (م²)",
      landArea: "مساحة الارض (م²)",
      unitCount: "عدد الوحدات",
      register: "سجل معنا", // "Register"
      name: "الاسم",         // "Name"
      email: "البريد الإلكتروني", // "Email"
      phone: "الهاتف",       // "Phone"
      messageForm: "الرسالة",     // "Message"
      submit: "إرسال",       // "Submit"
      nameRequired: "الاسم مطلوب.",
      validEmailRequired: "البريد الإلكتروني صالح مطلوب.",
      validPhoneRequired: "رقم الهاتف صالح مطلوب.",
      messageRequired: "الرسالة مطلوبة.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ar', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
